import PropTypes from "prop-types";
import { Component } from "react";

import { PLUGIN_LOGO_ICON_COMPONENTS } from "metabase/plugins";

class DefaultLogoIcon extends Component {
  static defaultProps = {
    height: 32,
  };
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    dark: PropTypes.bool,
  };

  render() {
    return (
      <svg width="146" height="25" viewBox="0 0 146 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.52442 3.16264C9.80759 2.22069 9.00985 1.23732 8.11288 1.64092C7.75616 1.80143 7.40412 1.9814 7.05809 2.18117C1.49662 5.39191 -0.408953 12.5028 2.80187 18.0638C6.01269 23.6248 13.124 25.5301 18.6855 22.3193C19.3172 21.9547 19.9017 21.5397 20.4369 21.0823C21.1239 20.4953 20.6338 19.4398 19.7404 19.304C19.411 19.2539 19.0773 19.3474 18.8166 19.5549C18.4581 19.8402 18.0739 20.1026 17.6651 20.3386C13.2142 22.9082 7.5229 21.3834 4.95326 16.9328C2.38361 12.4823 3.90865 6.79142 8.35954 4.22184C8.52148 4.12835 8.68506 4.04028 8.85007 3.95758C9.1739 3.79527 9.42014 3.50953 9.52442 3.16264Z" fill="#0D1600" />
        <path fillRule="evenodd" clipRule="evenodd" d="M22.1199 17.981C22.6174 18.0512 23.1036 17.7954 23.3234 17.3435C24.9555 13.9884 24.9466 9.90903 22.9405 6.43468C20.8106 2.74573 16.9642 0.665472 12.9924 0.620191C12.5006 0.614585 12.0635 0.926762 11.9054 1.39248C11.6219 2.22766 12.3668 3.00114 13.2479 3.04101C13.2752 3.04224 13.3024 3.04359 13.3296 3.04507C13.937 3.07796 14.0416 3.93128 13.5714 4.31714C11.0058 6.42249 10.6339 10.2095 12.7409 12.7757C14.8479 15.3418 18.6358 15.7154 21.2015 13.6101C21.4854 13.3771 21.9498 13.5839 21.8814 13.9447C21.7474 14.651 21.5312 15.3406 21.237 15.9981C20.8694 16.8199 21.2284 17.8553 22.1199 17.981ZM19.8452 11.9574C18.1912 13.3146 15.7493 13.0738 14.391 11.4195C13.0327 9.7652 13.2724 7.32385 14.9264 5.96661C16.5803 4.60937 19.0223 4.8502 20.3806 6.50451C21.7389 8.15881 21.4992 10.6002 19.8452 11.9574Z" fill="#6E9835" />
        <path d="M104.364 23.879C103.923 23.879 103.51 23.8447 103.124 23.7761C102.743 23.7127 102.427 23.6309 102.177 23.5306L102.96 21.0121C103.368 21.1335 103.735 21.1995 104.062 21.2101C104.394 21.2206 104.679 21.1467 104.918 20.9883C105.163 20.8299 105.362 20.5606 105.514 20.1805L105.718 19.6657L101.223 7.15234H104.878L107.472 16.0859H107.602L110.221 7.15234H113.901L109.03 20.6319C108.796 21.2866 108.478 21.8568 108.076 22.3426C107.679 22.8336 107.175 23.2111 106.566 23.4751C105.957 23.7444 105.223 23.879 104.364 23.879Z" fill="#0D1600" />
        <path d="M95.3434 19.555C94.0762 19.555 92.9803 19.2936 92.0557 18.7709C91.1365 18.2429 90.4267 17.509 89.9263 16.5692C89.426 15.6241 89.1758 14.5285 89.1758 13.2825C89.1758 12.0259 89.426 10.9277 89.9263 9.98784C90.4267 9.04274 91.1365 8.30883 92.0557 7.78612C92.9803 7.25813 94.0762 6.99414 95.3434 6.99414C96.6107 6.99414 97.7039 7.25813 98.6231 7.78612C99.5477 8.30883 100.26 9.04274 100.761 9.98784C101.261 10.9277 101.511 12.0259 101.511 13.2825C101.511 14.5285 101.261 15.6241 100.761 16.5692C100.26 17.509 99.5477 18.2429 98.6231 18.7709C97.7039 19.2936 96.6107 19.555 95.3434 19.555ZM95.3598 16.9415C95.9363 16.9415 96.4176 16.7831 96.8038 16.4663C97.1899 16.1442 97.4809 15.706 97.6767 15.1516C97.878 14.5972 97.9786 13.9662 97.9786 13.2587C97.9786 12.5512 97.878 11.9203 97.6767 11.3659C97.4809 10.8115 97.1899 10.3733 96.8038 10.0512C96.4176 9.72912 95.9363 9.56809 95.3598 9.56809C94.7778 9.56809 94.2883 9.72912 93.8913 10.0512C93.4997 10.3733 93.2033 10.8115 93.002 11.3659C92.8062 11.9203 92.7083 12.5512 92.7083 13.2587C92.7083 13.9662 92.8062 14.5972 93.002 15.1516C93.2033 15.706 93.4997 16.1442 93.8913 16.4663C94.2883 16.7831 94.7778 16.9415 95.3598 16.9415Z" fill="#0D1600" />
        <path d="M88.0457 3.09766V19.3175H84.5703V3.09766H88.0457Z" fill="#0D1600" />
        <path d="M79.4587 19.3173V7.15247H82.9341V19.3173H79.4587ZM81.2045 5.58435C80.6878 5.58435 80.2446 5.41803 79.8747 5.0854C79.5103 4.74748 79.3281 4.34357 79.3281 3.87366C79.3281 3.40903 79.5103 3.0104 79.8747 2.67777C80.2446 2.33986 80.6878 2.1709 81.2045 2.1709C81.7212 2.1709 82.1618 2.33986 82.5262 2.67777C82.896 3.0104 83.0809 3.40903 83.0809 3.87366C83.0809 4.34357 82.896 4.74748 82.5262 5.0854C82.1618 5.41803 81.7212 5.58435 81.2045 5.58435Z" fill="#0D1600" />
        <path d="M69.7918 12.2846V19.3174H66.3164V7.15254H69.6287V9.29881H69.7755C70.0529 8.59131 70.5179 8.03164 71.1706 7.61981C71.8233 7.2027 72.6146 6.99414 73.5447 6.99414C74.4149 6.99414 75.1736 7.17894 75.8208 7.54853C76.468 7.91812 76.9711 8.44611 77.3301 9.1325C77.6891 9.8136 77.8685 10.6267 77.8685 11.5718V19.3174H74.3931V12.1737C74.3986 11.4292 74.2028 10.8485 73.8057 10.4313C73.4087 10.009 72.8621 9.79776 72.1659 9.79776C71.6982 9.79776 71.2848 9.89544 70.9258 10.0908C70.5723 10.2862 70.2949 10.5713 70.0937 10.9461C69.8979 11.3157 69.7973 11.7619 69.7918 12.2846Z" fill="#0D1600" />
        <path d="M46.5117 19.3174V7.15254H49.824V9.29881H49.9708C50.2319 8.58603 50.667 8.02372 51.2762 7.61189C51.8853 7.20006 52.6141 6.99414 53.4626 6.99414C54.3219 6.99414 55.0534 7.2027 55.6572 7.61981C56.2609 8.03164 56.6634 8.59131 56.8646 9.29881H56.9951C57.2508 8.60187 57.7131 8.04484 58.382 7.62773C59.0565 7.20534 59.8532 6.99414 60.7724 6.99414C61.9418 6.99414 62.8909 7.35581 63.6197 8.07916C64.3539 8.79722 64.721 9.81624 64.721 11.1362V19.3174H61.2538V11.8015C61.2538 11.1257 61.0688 10.6188 60.699 10.2809C60.3291 9.94296 59.8668 9.774 59.3121 9.774C58.6812 9.774 58.189 9.96936 57.8354 10.3601C57.4819 10.7455 57.3051 11.255 57.3051 11.8886V19.3174H53.9358V11.7302C53.9358 11.1336 53.759 10.6584 53.4055 10.3046C53.0574 9.95088 52.5978 9.774 52.0267 9.774C51.6406 9.774 51.2925 9.86904 50.9825 10.0591C50.6779 10.2439 50.4359 10.5053 50.2564 10.8432C50.0769 11.1758 49.9872 11.5665 49.9872 12.0153V19.3174H46.5117Z" fill="#0D1600" />
        <path d="M45.1085 11.2076C45.1085 12.9764 44.7632 14.4812 44.0724 15.7219C43.3871 16.9627 42.4517 17.9105 41.266 18.5652C40.0858 19.2146 38.7587 19.5393 37.2847 19.5393C35.7999 19.5393 34.4674 19.212 33.2872 18.5572C32.1069 17.9025 31.1742 16.9548 30.4889 15.714C29.8036 14.4733 29.4609 12.9711 29.4609 11.2076C29.4609 9.43888 29.8036 7.93411 30.4889 6.69334C31.1742 5.45256 32.1069 4.50746 33.2872 3.85804C34.4674 3.20333 35.7999 2.87598 37.2847 2.87598C38.7587 2.87598 40.0858 3.20333 41.266 3.85804C42.4517 4.50746 43.3871 5.45256 44.0724 6.69334C44.7632 7.93411 45.1085 9.43888 45.1085 11.2076ZM41.5271 11.2076C41.5271 10.0619 41.3503 9.09569 40.9968 8.30898C40.6487 7.52228 40.1565 6.92565 39.5201 6.5191C38.8838 6.11255 38.1386 5.90927 37.2847 5.90927C36.4308 5.90927 35.6857 6.11255 35.0494 6.5191C34.413 6.92565 33.9181 7.52228 33.5646 8.30898C33.2165 9.09569 33.0424 10.0619 33.0424 11.2076C33.0424 12.3534 33.2165 13.3196 33.5646 14.1063C33.9181 14.893 34.413 15.4896 35.0494 15.8962C35.6857 16.3027 36.4308 16.506 37.2847 16.506C38.1386 16.506 38.8838 16.3027 39.5201 15.8962C40.1565 15.4896 40.6487 14.893 40.9968 14.1063C41.3503 13.3196 41.5271 12.3534 41.5271 11.2076Z" fill="#0D1600" />
      </svg>

    );
  }
}

export default function LogoIcon(props) {
  const [Component = DefaultLogoIcon] = PLUGIN_LOGO_ICON_COMPONENTS;
  return <Component {...props} />;
}
