import PropTypes from "prop-types";
import { Component } from "react";

import { PLUGIN_LOGO_ICON_COMPONENTS } from "metabase/plugins";

class DefaultLogoLoginIcon extends Component {
    static defaultProps = {
        height: 32,
    };
    static propTypes = {
        width: PropTypes.number,
        height: PropTypes.number,
        dark: PropTypes.bool,
    };

    render() {
        return (
            <svg width="232" height="61" viewBox="0 0 232 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clip-rule="evenodd" d="M23.8343 13.2051C24.3775 11.3981 22.8472 9.51171 21.1266 10.286C20.4424 10.5938 19.7671 10.939 19.1034 11.3222C8.43532 17.4811 4.78002 31.1213 10.9391 41.7885C17.0981 52.4557 30.7392 56.1104 41.4073 49.9515C42.6191 49.252 43.7404 48.4558 44.7671 47.5785C46.0848 46.4525 45.1448 44.4279 43.4312 44.1675V44.1675C42.7994 44.0715 42.1593 44.2508 41.6593 44.6487C40.9714 45.196 40.2346 45.6993 39.4503 46.1521C30.9125 51.0811 19.9955 48.1562 15.0663 39.6191C10.1372 31.0821 13.0626 20.1657 21.6003 15.2367C21.9108 15.0575 22.2244 14.8886 22.5408 14.73C23.162 14.4187 23.6343 13.8705 23.8343 13.2051V13.2051Z" fill="#011F4E" />
                <path fillRule="evenodd" clip-rule="evenodd" d="M47.9939 41.6301C48.9482 41.7647 49.881 41.274 50.3026 40.4073C53.4333 33.9713 53.4162 26.1463 49.5682 19.4817C45.4826 12.4058 38.1047 8.41541 30.4863 8.32827C29.5429 8.31748 28.7045 8.91631 28.4012 9.80969C27.8573 11.4117 29.2862 12.8954 30.9763 12.972C31.029 12.9744 31.0816 12.977 31.1343 12.9798C32.2993 13.0431 32.4999 14.6798 31.598 15.42V15.42C26.6765 19.4585 25.9632 26.7228 30.0049 31.6453C34.0465 36.5677 41.3126 37.2843 46.2341 33.2458V33.2458C46.7779 32.7995 47.6677 33.1957 47.5365 33.8869C47.2794 35.2421 46.8648 36.5651 46.3004 37.8265C45.5951 39.4028 46.2839 41.3889 47.9939 41.6301ZM43.6325 30.0756C40.4598 32.6791 35.7757 32.2172 33.1702 29.0438C30.5647 25.8705 31.0245 21.1875 34.1972 18.584C37.3699 15.9805 42.054 16.4425 44.6595 19.6158C47.265 22.7891 46.8052 27.4721 43.6325 30.0756Z" fill="#0458DD" />
                <path d="M205.759 52.9433C204.914 52.9433 204.121 52.8775 203.381 52.7458C202.65 52.6243 202.045 52.4673 201.565 52.2749L203.068 47.4438C203.85 47.6768 204.554 47.8034 205.18 47.8236C205.817 47.8439 206.364 47.7021 206.824 47.3982C207.293 47.0944 207.674 46.5779 207.966 45.8487L208.357 44.8612L199.734 20.8579H206.745L211.722 37.9944H211.972L216.996 20.8579H224.053L214.711 46.7146C214.262 47.9705 213.652 49.0643 212.88 49.9961C212.118 50.938 211.153 51.6621 209.985 52.1685C208.816 52.685 207.408 52.9433 205.759 52.9433Z" fill="#011F4E" />
                <path d="M188.456 44.6486C186.025 44.6486 183.923 44.1473 182.149 43.1446C180.386 42.1318 179.025 40.724 178.065 38.9213C177.105 37.1084 176.625 35.0068 176.625 32.6166C176.625 30.2061 177.105 28.0995 178.065 26.2968C179.025 24.4838 180.386 23.0761 182.149 22.0734C183.923 21.0606 186.025 20.5542 188.456 20.5542C190.887 20.5542 192.984 21.0606 194.747 22.0734C196.521 23.0761 197.887 24.4838 198.847 26.2968C199.807 28.0995 200.287 30.2061 200.287 32.6166C200.287 35.0068 199.807 37.1084 198.847 38.9213C197.887 40.724 196.521 42.1318 194.747 43.1446C192.984 44.1473 190.887 44.6486 188.456 44.6486ZM188.487 39.6353C189.593 39.6353 190.516 39.3314 191.257 38.7238C191.998 38.106 192.556 37.2653 192.932 36.2019C193.318 35.1385 193.511 33.9282 193.511 32.571C193.511 31.2139 193.318 30.0036 192.932 28.9402C192.556 27.8767 191.998 27.0361 191.257 26.4183C190.516 25.8005 189.593 25.4916 188.487 25.4916C187.371 25.4916 186.432 25.8005 185.67 26.4183C184.919 27.0361 184.351 27.8767 183.965 28.9402C183.589 30.0036 183.401 31.2139 183.401 32.571C183.401 33.9282 183.589 35.1385 183.965 36.2019C184.351 37.2653 184.919 38.106 185.67 38.7238C186.432 39.3314 187.371 39.6353 188.487 39.6353Z" fill="#011F4E" />
                <path d="M174.458 13.0793V44.1924H167.791V13.0793H174.458Z" fill="#011F4E" />
                <path d="M157.989 44.1928V20.858H164.655V44.1928H157.989ZM161.338 17.85C160.347 17.85 159.496 17.5309 158.787 16.8929C158.088 16.2447 157.738 15.4699 157.738 14.5685C157.738 13.6773 158.088 12.9126 158.787 12.2745C159.496 11.6263 160.347 11.3022 161.338 11.3022C162.329 11.3022 163.174 11.6263 163.873 12.2745C164.582 12.9126 164.937 13.6773 164.937 14.5685C164.937 15.4699 164.582 16.2447 163.873 16.8929C163.174 17.5309 162.329 17.85 161.338 17.85Z" fill="#011F4E" />
                <path d="M139.444 30.7024V44.1929H132.777V20.858H139.131V24.9751H139.413C139.945 23.6179 140.837 22.5443 142.089 21.7544C143.341 20.9543 144.859 20.5542 146.643 20.5542C148.312 20.5542 149.767 20.9087 151.009 21.6176C152.25 22.3266 153.215 23.3394 153.904 24.656C154.593 25.9625 154.937 27.5222 154.937 29.3351V44.1929H148.27V30.4897C148.281 29.0617 147.905 27.9476 147.143 27.1475C146.382 26.3373 145.333 25.9321 143.998 25.9321C143.101 25.9321 142.308 26.1195 141.619 26.4942C140.941 26.869 140.409 27.4159 140.023 28.135C139.647 28.8439 139.454 29.6998 139.444 30.7024Z" fill="#011F4E" />
                <path d="M94.7852 44.1929V20.858H101.139V24.9751H101.42C101.921 23.6078 102.756 22.5292 103.924 21.7392C105.093 20.9492 106.491 20.5542 108.118 20.5542C109.767 20.5542 111.17 20.9543 112.328 21.7544C113.486 22.5443 114.258 23.6179 114.644 24.9751H114.895C115.385 23.6382 116.272 22.5697 117.555 21.7696C118.849 20.9593 120.377 20.5542 122.14 20.5542C124.383 20.5542 126.204 21.248 127.602 22.6355C129.01 24.0129 129.715 25.9676 129.715 28.4996V44.1929H123.064V29.7757C123.064 28.4793 122.709 27.507 121.999 26.8589C121.29 26.2107 120.403 25.8866 119.339 25.8866C118.129 25.8866 117.185 26.2613 116.506 27.0108C115.828 27.7501 115.489 28.7275 115.489 29.9428V44.1929H109.026V29.639C109.026 28.4945 108.687 27.583 108.009 26.9044C107.341 26.2259 106.46 25.8866 105.364 25.8866C104.623 25.8866 103.956 26.0689 103.361 26.4335C102.777 26.788 102.312 27.2893 101.968 27.9375C101.624 28.5755 101.452 29.325 101.452 30.1859V44.1929H94.7852Z" fill="#011F4E" />
                <path d="M92.0936 28.6362C92.0936 32.0291 91.4311 34.9156 90.1061 37.2956C88.7916 39.6757 86.9971 41.4937 84.7227 42.7495C82.4588 43.9953 79.9132 44.6181 77.0859 44.6181C74.2377 44.6181 71.6816 43.9902 69.4177 42.7343C67.1537 41.4785 65.3645 39.6605 64.0499 37.2804C62.7354 34.9004 62.0781 32.019 62.0781 28.6362C62.0781 25.2434 62.7354 22.3569 64.0499 19.9768C65.3645 17.5967 67.1537 15.7838 69.4177 14.5381C71.6816 13.2822 74.2377 12.6543 77.0859 12.6543C79.9132 12.6543 82.4588 13.2822 84.7227 14.5381C86.9971 15.7838 88.7916 17.5967 90.1061 19.9768C91.4311 22.3569 92.0936 25.2434 92.0936 28.6362ZM85.2235 28.6362C85.2235 26.4385 84.8845 24.585 84.2063 23.076C83.5386 21.5669 82.5944 20.4224 81.3738 19.6426C80.1531 18.8627 78.7238 18.4728 77.0859 18.4728C75.4479 18.4728 74.0186 18.8627 72.7979 19.6426C71.5773 20.4224 70.6279 21.5669 69.9498 23.076C69.282 24.585 68.9482 26.4385 68.9482 28.6362C68.9482 30.834 69.282 32.6874 69.9498 34.1965C70.6279 35.7055 71.5773 36.85 72.7979 37.6299C74.0186 38.4097 75.4479 38.7996 77.0859 38.7996C78.7238 38.7996 80.1531 38.4097 81.3738 37.6299C82.5944 36.85 83.5386 35.7055 84.2063 34.1965C84.8845 32.6874 85.2235 30.834 85.2235 28.6362Z" fill="#011F4E" />
            </svg>

        );
    }
}

export default function LogoIcon(props) {
    const [Component = DefaultLogoLoginIcon] = PLUGIN_LOGO_ICON_COMPONENTS;
    return <Component {...props} />;
}
